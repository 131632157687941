<template>
	<div v-if="new Array('Admin', 'SuperAdmin').includes(profile.role)">
		<v-card height="100%" class="">
			<v-card-title>Shutdown</v-card-title>
			<v-card-text class="my-3 mb-12">
				<v-row>
					<v-col cols="12" class="text-left">

                        <v-switch class="my-5" v-model="shutdownData.shutdown" label="Shutdown (ATTENTION : Ceci rendra Valy inaccessible à tous, sauf aux développeurs)" color="error" hide-details></v-switch>
                        
                        <v-text-field class="my-5" label="Titre" v-model="shutdownData.shutdownTitle" />

                        <v-textarea class="my-5" label="Message" v-model="shutdownData.shutdownMessage"></v-textarea>

                        <div class="mx-4 mt-10">
                            <router-link to="/errorShutdown">Tester la page d'erreur</router-link>
                        </div>

						<span>
							<v-btn class="mt-10 mx-3" color="error" x-large @click="resetConfig()">Cancel</v-btn>
							<v-btn class="mt-10 mx-3" color="primary" x-large @click="saveConfig()">Valider</v-btn>
						</span>

					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	import logger from "@/assets/functions/logger"

    import Config from '@/classes/Config'
    import Swal from 'sweetalert2/dist/sweetalert2.js'

	export default {
		name: "Shutdown",
		props: ["user", "profile", "notifications", "config", "currentRoom"],
		components: {},
		data() {
			return {
                shutdownData:{},
				unsub: [],
			}
		},

		created() {
            this.resetConfig()
        },

		methods: {
            resetConfig(){
                this.shutdownData = JSON.parse(JSON.stringify(this.config))
            },

            saveConfig(){
                let tmp_config = new Config (this.shutdownData.shutdown, this.shutdownData.shutdownTitle, this.shutdownData.shutdownMessage)
                tmp_config.save()
                .then(() => {
                    Swal.fire({
                        title: 'Configuration enregistrée',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                })
            }
        },

		destroyed() {
			this.unsub.forEach((unsub) => {
				unsub()
			})
		},
	}
</script>
